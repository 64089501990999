@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

poppins {
  font-family: 'Poppins', sans-serif;
}

.pagination{
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.pagination a{
  padding: 10px;
  color: black;
}

.pagination_link--disabled{
  color: rgb(198, 197, 202);
}

.pagination_link--active{
  color: white;
  background: rgb(34 197 94);;
  border-radius: 5px;
}
